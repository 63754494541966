import { Box, IconButton, useTheme } from "@mui/material";
import { useContext , useState, useEffect, useRef,scrollContainerRefs,useMemo } from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import LogoutIcon from '@mui/icons-material/Logout';
import PauseIcon from '@mui/icons-material/Pause';
import AppBar from '@mui/material/AppBar';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import HomeIcon from '@mui/icons-material/Home';
import Pagination from '../pages/Pagination';
import { ReactSession } from 'react-client-session';
import { navigate } from '@reach/router';
import { Link } from "react-router-dom";
import { TooltipComponent } from '@syncfusion/ej2-react-popups'
import { useStateContext } from '../contexts/ContextProvider'
import { AiOutlineMenu } from 'react-icons/ai';
import { BsArrowsFullscreen } from "react-icons/bs";
import { BiSleepy } from "react-icons/bi";
import { ImSleepy } from "react-icons/im";
import NoSleep from 'nosleep.js';
import Swal from 'sweetalert2';

const styles = {
  circle: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    backgroundColor: 'red',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '0.8em',
    marginLeft: '5px',
  }
};

const Footer = ({handlePlayPause , newArrivalsCount, newDelayedCount, TotalRecords, buttonRef, onData ,currentData, handleDelayedTabClick, handlePriorityTabClick, currentP, post ,pageSize,onPagenumber,handleStatusTabClick,handleLastStatusTabClick,statusKitchen}) => {
  
   const [currentPage, setCurrentPage] = useState(currentP);
   const kdsNotificationTimer = ReactSession.get("kdsNotificationTimer");
  
   const logOutClick = () => {
       Swal.fire({
        title: 'Are you sure?',
        text: ' logout!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, logout it!',
        cancelButtonText: 'No, cancel!',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            'Successfully!',
            'Logout.',
            'success'
          );
          ReactSession.set("currentTags", null);
          ReactSession.set("kdsName", null);
          ReactSession.set("authToken", null);
          ReactSession.set("userData", null);
          ReactSession.set("kdsName", null);
          ReactSession.set("userSetting", null);
          ReactSession.set("alltags", null);
          ReactSession.set("domain", null);
          navigate('/');
          window.location.reload();
          // Perform your action here if confirmed
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'I have change my mind :)',
            'error'
          );
        }
      });
    
   
  };

  const settingClick = () => {
    navigate('/settings');
  };

  const { currentColor, activeMenu, setActiveMenu, handleClick, isClicked, setScreenSize, screenSize } = useStateContext()
   const [isNoSleepEnabled, setIsNoSleepEnabled] = useState(true);
   const [noSleepInstance, setNoSleepInstance] = useState(new NoSleep());
   const [isFullscreen, setIsFullscreen] = useState(false);
   const currentFontVal = ReactSession.get('currentFont');
   useEffect(() => {
      const handleResize = () => setScreenSize(window.innerWidth)

      window.addEventListener('resize', handleResize)

      handleResize()

      return () => window.removeEventListener('resize', handleResize)
      
    }, [])

    useEffect(() => {
      if (screenSize <= 900) {
        setActiveMenu(false)
      } else {
        setActiveMenu(true)
      }
      toggleNoSleep()
    }, [screenSize,])

     
    
    const handleActiveMenu = (e) => { e.stopPropagation(); setActiveMenu(!activeMenu)}
    const toggleNoSleep = () => {
      // If noSleepInstance is not initialized, create a new instance
      if (!noSleepInstance) {
        const noSleep = new NoSleep();
        setNoSleepInstance(noSleep);
      }
      
     
      // Toggle the action of preventing the screen from sleeping
      if (isNoSleepEnabled) {
        noSleepInstance.disable();
      } else {
        noSleepInstance.enable();
      }
  
      // Update the state to reflect the current status
      setIsNoSleepEnabled(!isNoSleepEnabled);
    };

    const toggleFullscreen = () => {
      if (!isFullscreen) {
        enterFullscreen();
      } else {
        exitFullscreen();
      }
      setIsFullscreen(!isFullscreen);
    };
  
    const enterFullscreen = () => {
      const element = document.documentElement;
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    };
  
    const exitFullscreen = () => {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    };

    const handleLinkClick = (event) => {
      event.preventDefault();
      // Perform additional actions or logic here
      console.log('Link clicked');
    };

    // const handleDelayedTabClick = (event) => {
    //   handleDelayedTabClick(event);
    //   // Perform additional actions or logic here
    //   navigate('/delayed');
    // };
    
 
  return (
    
    <div className={`${currentFontVal} bg-slate-50  sticky bottom-0 nav-item bottom-0 right-0 z-20  hidden lg:block md:block md:visible lg:visible sm:invisible`}>
      
        <Tabs indicatorColor="secondary" className={`bottombar ${currentFontVal}`}  textColor="inherit" variant="fullWidth" aria-label="full width tabs example">
        <Link to='settings' onClick={logOutClick} className={`${currentFontVal}`} ><Tab className={`logout ${currentFontVal}`}  icon={<LogoutIcon />} onClick={logOutClick} /></Link>
        <Link to='settings' className={`${currentFontVal}`} ><Tab  className={`print ${currentFontVal}`}  icon={<SettingsSuggestIcon />}  /></Link>
        {/* <NavButton  customFunc={handleActiveMenu}   title='Menu' className={`bumb ${currentFontVal}`} icon={<AiOutlineMenu/>} color={currentColor} />
        <NavButton   customFunc={toggleFullscreen} title='Full Screen' className={`print ${currentFontVal}`} icon={<BsArrowsFullscreen/>} color={currentColor} /> */}
        <div className="" onClick={handleLinkClick} ><Tab className="cooking" icon={<BsArrowsFullscreen/>}   onClick={toggleFullscreen} /></div> 
        <div className="" onClick={handleLinkClick} ><Tab className="bumb" icon={<AiOutlineMenu/>}   onClick={handleActiveMenu} /></div> 
        <Link to='/' className={`${currentFontVal}`} ><Tab  className={`print ${currentFontVal}`}  icon={<HomeIcon />}  /></Link>
        {/* <NavButton customFunc={toggleNoSleep} title={isNoSleepEnabled? 'Enable NoSleep' : 'Disable NoSleep'}  icon={ isNoSleepEnabled  ? <BiSleepy/> : <ImSleepy/>} color={currentColor} /> */}
            {/* <Tab className="priority" label="Priority"  /> */}
            <Tab  onClick={handlePriorityTabClick}  className={`priority  ${onData} ${currentFontVal} `}   label={statusKitchen=== 4 ? `Back to Main` :   `New Arrivals` }  disabled={statusKitchen=== 2 || statusKitchen=== 1 || statusKitchen=== 3 ? "disabled " : ""}  /> 
            <Tab  onClick={handleDelayedTabClick} className={`delayed ${onData} ${currentFontVal}  ${newDelayedCount > 0 ? "blink_me" : ""}`}   label={statusKitchen === 3 ? (<span>Back to Main <strong style={styles.circle}>{TotalRecords}</strong></span>) : (
    <span>Delayed <strong style={styles.circle}>{newDelayedCount}</strong></span>
  )
} disabled={statusKitchen=== 2 || statusKitchen=== 1 || statusKitchen=== 4 ? "disabled " : ""}  /> 
          {/* <Tab className="bumb" label="Bump"  /> */}
          {/* <Tab className="cooking" label="Cooking"  /> */}
          {/* <Tab className="join"label="Join"  /> */}
          {/*<Tab className="print"  icon={<ArrowBackIosNewSharpIcon />} />
           <Tab className="cooking" label="1"  />
          <Tab className="info" label="" icon={<ArrowForwardIosSharpIcon />}  />*/}
          
        <Pagination className={`pagination-bar ${currentFontVal} `} currentPage={currentP} totalCount={ post ? post.length : 1  }
        pageSize={pageSize} onPageChange={page => onPagenumber(page)}/>
          <Tab  className={`recall ${currentFontVal}`}  label={statusKitchen=== 1 ? `Back to Main ${TotalRecords}` : "Completed Dockets"} disabled={statusKitchen=== 2 || statusKitchen=== 3 || statusKitchen=== 4 ? "disabled " : ""} onClick={handleStatusTabClick} />
        
          <Tab  className={`recall-last ${currentFontVal}`}  label={statusKitchen=== 2 ? "Back to Main" : "Recall Last"} disabled={statusKitchen=== 1 || statusKitchen=== 3 || statusKitchen=== 4 ? "disabled " : ""}   onClick={handleLastStatusTabClick} />
           {kdsNotificationTimer == 1 && (<Tab className={`pause ${currentFontVal}`}  icon={<PauseIcon />} onClick={handlePlayPause} ref={buttonRef} />)  }
          
        </Tabs>
     
    </div>
  )
};

const NavButton = ({title, customFunc, icon, color, dotColor}) => {
  return (
  <TooltipComponent content={title} position='BottomCenter' cssClass='my-tooltip'>
    <button
     type='button'
     onClick={() => customFunc()}
     style={{ color }}
     className="relative p-3 text-xl rounded-full hover:bg-light-gray"
     >

     <span
     style={{background: dotColor }}
     className="absolute inline-flex w-2 h-2 rounded-full top-2 right-2"
     >
     </span>
     {icon}
    </button>
  </TooltipComponent>
  )
}

export default Footer