export default function validate(values) {
  let errors = {};
   console.log(values)
   const domainRegex = /^[a-zA-Z0-9]+\.[a-zA-Z0-9]+\.[a-zA-Z]{2,}$/;
  if (!values.pin) {
    errors.pin = "Pin is required";
  }else if (values.pin == 38946381){
    errors.pin = "Incorrect PIN";
  }else  if (!values.domain) {
    errors.domain = "Domain is required";
  }
  else if (!domainRegex.test(values.domain)) {
   
    errors.domain='Invalid domain format. Expected format: subdomain.domain.tld';
  }
  // if (!values.userName) {
  //   errors.userName = "Kitchen Display Name is required";
  // }
  
  return errors;
}
